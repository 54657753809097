<template>
  <div v-if="form">
    <b-row class="no-gutters">
      <b-col>
        <h1 class="font-weight-bold header-main text-uppercase mb-3">
          ข้อมูลบัญชีธนาคาร
        </h1>
      </b-col>
    </b-row>
    <div class="bg-white p-3" v-if="$isLoading">
      <b-row class="pl-1">
        <b-col cols="6">
          <div class="panel d-flex align-items-md-center">
            <b-form-checkbox
              size="lg"
              id="sameLang"
              class="mt-0 mt-sm-3"
              @change="useSameLanguage"
              v-model="form.isSameLanguage"
              >ใช้เหมือนกันทุกภาษา
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="6" class="text-right">
          <div class="panel">
            <b-button
              type="button"
              class="btn btn-language"
              v-for="(language, index) in languageList"
              v-bind:key="index"
              v-bind:class="[languageActive == language.id ? 'active' : '']"
              @click="changeLanguage(language.id, index)"
              :disabled="form.isSameLanguage ? true : false"
            >
              <span class="text-uppercase">{{ language.nation }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <InputSelect
            class="mt-3"
            title="ชื่อธนาคาร"
            name="payment"
            v-model="form.bankId"
            isRequired
            v-bind:options="bank"
            valueField="id"
            textField="name"
            @onDataChange="handleChangeSourceBank"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <InputText
            textFloat="เลขที่บัญชี"
            placeholder="เลขที่บัญชี"
            type="text"
            name="name"
            v-model="form.accountNo"
            isRequired
            :isValidate="$v.form.accountNo.$error"
            :v="$v.form.accountNo"
          />
        </b-col>
        <b-col md="6">
          <div v-for="(item, index) in form.translationList" v-bind:key="index">
            <div
              v-bind:class="[languageActive == item.languageId ? '' : 'd-none']"
            >
              <InputText
                textFloat="ชื่อบัญชีธนาคาร"
                placeholder="ชื่อบัญชีธนาคาร"
                type="text"
                name="name"
                v-model="item.name"
                isRequired
                :img="imageLogoLang"
                :isValidate="
                  $v.form.translationList.$each.$iter[index].name.$error
                "
                :v="$v.form.translationList.$each.$iter[index].name"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <InputText
            textFloat="ลำดับการแสดง"
            placeholder="ลำดับการแสดง"
            type="text"
            name="sortorder"
            @onKeypress="isNumber($event)"
            v-model="form.sortOrder"
            :isValidate="$v.form.sortOrder.$error"
            :v="$v.form.sortOrder"
          />
        </b-col>
        <b-col md="6">
          <label class="font-weight-bold main-label">
            สถานะการแสดงผล
            <span class="text-danger">*</span>
          </label>
          <div>
            <b-form-checkbox
              switch
              v-model="form.enabled"
              class="radio-active"
              size="lg"
            >
              <span class="ml-2 main-label">{{
                form.enabled ? "ใช้งาน" : "ไม่ใช้งาน"
              }}</span>
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col md="6">
          <!-- <b-button
              v-if="isEdit"
              class="btn btn-danger btn-details-set mr-md-2"
              :disabled="isDisable"
              @click="openModalDelete(form.translationList[0].name)"
              >ลบ</b-button
            > -->
          <router-link to="/bank">
            <b-button :disabled="isDisable" class="btn-details-set btn-cancel"
              >ย้อนกลับ</b-button
            >
          </router-link>
        </b-col>
        <b-col md="6" class="text-sm-right">
          <button
            type="button"
            @click="checkForm(0)"
            :disabled="isDisable"
            class="btn btn-main btn-details-set ml-md-2 text-uppercase"
          >
            บันทึก
          </button>
          <button
            type="button"
            @click="checkForm(1)"
            :disabled="isDisable"
            class="btn btn-main btn-details-set ml-md-2 text-uppercase"
          >
            บันทึกและออก
          </button>
        </b-col>
      </b-row>
    </div>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="isModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import { required } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import Vue from "vue";

export default {
  name: "BannerDetails",
  components: {
    InputText,
    InputSelect,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoadingImage: false,
      isLoadingMobileImage: false,
      isSameLanguage: false,
      coverImgType: 1,
      showVideo: "",
      showPreview: "",
      mobileCoverImgType: 1,
      mobileShowVideo: "",
      mobileShowPreview: "",
      isEdit: false,
      images: "",
      mobileImages: "",
      modalMessage: "",
      isDisable: false,
      fileNameComputer: "",
      fileNameMobile: "",
      languageList: [],
      bank: [],
      languageActive: 1,
      imageLogoLang: "",
      form: null,
    };
  },
  validations: {
    form: {
      accountNo: { required },
      sortOrder: { required },
      translationList: {
        $each: {
          name: { required },
        },
      },
    },
  },
  created: async function () {
    await this.getDatas();
    await this.changeLanguage(1, 0);
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeSameLang: function (value) {
      this.form.isSameLanguage = value;
    },
    handleChangeSourceBank: async function (value) {
      this.form.bankId = value;
    },
    getDatas: async function () {
      this.$isLoading = false;

      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );

      if (languages.result == 1) {
        this.languageList = languages.detail;
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/bank/Getfin/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;
        this.$v.form.$reset();

        if (this.form.id > 0) {
          this.isEdit = true;
        }

        this.$isLoading = true;
      }

      if (this.form.isSameLanguage) {
        this.imageLogoLang = "";
        this.languageActive = this.form.mainLanguageId;
      } else {
        // var index = this.languageList
        //   .map(function (x) {
        //     return x.id;
        //   })
        //   .indexOf(this.form.mainLanguageId);
        this.imageLogoLang = this.languageList[0].imageUrl;
      }

      let uploads = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/bank/list`,
        null,
        this.$headers,
        null
      );

      if (uploads.result == 1) {
        this.bank = uploads.detail.dataList;

        if (this.id > 0) {
          this.form.bankId = this.form.bankId;
        } else {
          this.form.bankId = this.bank[0].id;
        }
      }
    },
    changeLanguage(id, index) {
      if (!this.form.isSameLanguage) {
        this.languageActive = id;
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
    },
    checkForm: async function (flag) {
      if (this.form.isSameLanguage) {
        await this.useSameLanguage();
      }
      this.$v.$touch();
      if (this.$v.$error) {
        await this.checkValidateTranslationList();
        return;
      }
      this.modalAlertShow = false;
      this.flag = flag;
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/bank/Getfin/save`,
        null,
        this.$headers,
        this.form
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.existId = data.detail;
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        if (this.flag == 1) {
          setTimeout(() => {
            this.$router.push({
              path: `/bank`,
            });
          }, 3000);
        } else {
          setTimeout(() => {
            this.$refs.modalAlert.hide();
          }, 3000);
          if (this.id > 0) {
            this.getDatas();
          } else {
            this.form.id = this.existId;
            this.id = this.existId;
            this.isEdit = true;
            this.$router.push({ path: `/bank/details/${this.existId}` });
          }
        }
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.imageLogoLang = "";
          this.form.mainLanguageId = this.languageActive;
          let data = this.form.translationList.filter(
            (val) => val.languageId == this.form.mainLanguageId
          );

          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              for (
                let index = 0;
                index < this.form.translationList.length;
                index++
              ) {
                this.form.translationList[index].name = data.name;
              }
            }
          }
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;

          let data = this.form.translationList.filter(
            (val) => val.languageId != this.form.mainLanguageId
          );
          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.name = "";
            }
          }
        }
      });
    },
    checkValidateTranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.translationList.$each.$iter[index].$error) {
            this.languageActive =
              this.$v.form.translationList.$model[index].languageId;

            this.imageLogoLang = this.languageList[index].imageUrl;

            isError = true;
          }
        }
      });
    },
    btnDelete: async function () {
      this.$refs.isModalAlertConfirm.hide();

      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/bank/Getfin/delete/${this.id}`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/bank`,
          });
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    openModalDelete(name) {
      this.modalMessage = "คุณต้องการลบ " + name + " ใช่หรือไม่?";
      this.$refs.isModalAlertConfirm.show();
    },
  },
};
</script>

<style scoped>
.panel {
  font-size: 17px;
  color: #16274a;
}
.b-contain {
  padding-bottom: 42.9%;
}
.banner-video::before {
  padding-top: 42.9%;
}
</style>
